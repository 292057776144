const chi_siamo_subtitle = document.querySelector('#chi-siamo__subtitle');
const chi_siamo_p1 = document.querySelector('#chi-siamo-p1');
const chi_siamo_p2 = document.querySelector('#chi-siamo-p2');
const icons_subtitle = document.querySelector('#icons__subtitle');
const lake_desc_subtitle = document.querySelector('#lake-desc-subtitle');
const camp_subtitle = document.querySelector('#section-camp-subtitle');

const obsCallback = function(entries, observer) { 

  entries.forEach( (entry) => {
    if (!entry.isIntersecting) return;
    if (entry.target.id.includes('subtitle')) {
      entry.target.style.marginLeft = "0rem";
      entry.target.style.opacity = "1";
    }
    if (entry.target.id.includes('chi-siamo'))
      entry.target.style.transform = "scale(1)";
  })
};

const obsOptions = {
	root: null, //se il root è settato su null indica il viewport
	thresold: 1 ,
	//la percentuale di altezza dell’elemento “osservato” che sarà visibile nel root
	//prima di eseguire l’evento
	rootMargin: "0px"
};

const observer = new IntersectionObserver(obsCallback, obsOptions);
if (chi_siamo_subtitle)
  observer.observe(chi_siamo_subtitle); 
if (chi_siamo_p1) 
  observer.observe(chi_siamo_p1);
if (chi_siamo_p2)
  observer.observe(chi_siamo_p2);
if (icons_subtitle)
  observer.observe(icons_subtitle);
if (lake_desc_subtitle)
  observer.observe(lake_desc_subtitle);
if (camp_subtitle)
  observer.observe(camp_subtitle);


// Change language
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const lang_box_footer = document.querySelector('.footer__lang ');
const lang_box_header = document.querySelector('.lang_banner');
function change_language(e) {
  if (e.target.id) {
    console.log(`From ${getCookie('lang')} to ${e.target.id}`)
    document.cookie = `lang=${e.target.id};path=/`;
    location.reload();
  }
}
lang_box_header.addEventListener( 'click', e => change_language(e));
lang_box_footer.addEventListener( 'click', e => change_language(e));
